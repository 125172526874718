import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// Chartコンポーネント
import SearchFilter from "../../components/parts/chart/search-filter"
import ByPowerSupply from "../../components/parts/chart/by-power-supply"
import PowerGeneration from "../../components/parts/chart/power-generation"
import ByPowerSupplyFuture from "../../components/parts/chart/by-power-supply-future"
import PowerGenerationFuture from "../../components/parts/chart/power-generation-future"
import MarketTrend from "../../components/parts/chart/market-trend"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.searchInit = {
      filter1Init: 0,
      filter2Init: 1,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
        <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <ByPowerSupply id="byPowerSupply" defaultChart="all-world" highlight="pv" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PowerGeneration id="powerGeneration" defaultChart="all-world" highlight="pv" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <ByPowerSupplyFuture id="byPowerSupplyFuture" defaultChart="all-nze" highlight="pv" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PowerGenerationFuture id="powerGenerationFuture" defaultChart="all-nze" highlight="pv" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <MarketTrend id="marketTrend" chartData="pv" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`